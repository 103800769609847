import Banner, {BannerEnum, BannerReference} from "@/models/Banner"
import ConstantTool from "@/services/tool/ConstantTool"
import SessionModule from "@/store/SessionModule"
import {getModule} from "vuex-module-decorators"
import JsonTool from "@/services/tool/JsonTool"
import SnackbarModule from "@/store/SnackbarModule"
import Address from "@/models/Address";

export default class BannerService {

    static typeToName(type: BannerEnum) {
        switch (type) {
            case BannerEnum.SPLASH: return "Bienvenida"
            case BannerEnum.REGULAR: return "Normal"
            case BannerEnum.INTERSTITIAL: return "Pantalla completa"
        }
    }

    static referenceToName(type: BannerReference) {
        switch (type) {
            case BannerReference.HOME_TOP: return "Inicio - Cabecera";
            case BannerReference.PRODUCTS: return "Productos";
            case BannerReference.NOVELTIES: return "Novedades";
            case BannerReference.HOME_TOP_2: return "Inicio - Footer";
        }
    }

    static postBanner(component: Vue, banner: Banner, image: File) {
        (<any>component).loading = true
        let formData: FormData = new FormData()
        formData.set("name", banner.name!)
        formData.set("url", banner.url!)
        formData.set("type", banner.type!)
        formData.set("reference", banner.reference!)
        formData.set("image", image)
        component.axios.post(ConstantTool.BASE_URL + "/api/banners",
            formData, {
                headers: {Authorization: getModule(SessionModule).session.token}
            })
            .then(response => {
                let item = JsonTool.jsonConvert.deserializeObject(response.data, Banner)
                component.$router.push({path: "/banners/" + item.id})
            })
            .catch(() => getModule(SnackbarModule).makeToast("No se pudo crear el banner"))
            .finally(() => (<any>component).loading = false)
    }

    static getBanners(component: Vue, banners: Banner[]) {
        (<any>component).loading = true
        component.axios.get(ConstantTool.BASE_URL + "/public/banners", {
            headers: {Authorization: getModule(SessionModule).session.token}
        })
            .then(response => {
                let list = JsonTool.jsonConvert.deserializeArray(response.data, Banner)
                banners.splice(0, banners.length)
                list.forEach(v => banners.push(v))
            })
            .catch((error) => {
                console.log(error)
                getModule(SnackbarModule).makeToast("No se han podido obtener los banners")
            })
            .finally(() => (<any>component).loading = false)
    }

    static async getBanner(component: Vue, bannerId: number) {
        (<any>component).loading = true
        component.axios.get(ConstantTool.BASE_URL + "/public/banners/" + bannerId, {
            headers: {Authorization: getModule(SessionModule).session.token}
        })
            .then(response => {
                (<any>component).banner = JsonTool.jsonConvert.deserializeObject(response.data, Banner)
            })
            .catch(() => getModule(SnackbarModule).makeToast("No se pudo obtener el banner"))
            .finally(() => (<any>component).loading = false)
    }

    static async getBannersByReference(component: Vue, banners: Banner[], reference: BannerReference) {
        // @ts-ignore
        component.loading = true
        try {
            let response = await component.axios.get(ConstantTool.BASE_URL + "/public/banners/reference/" + reference.toString())
            banners.splice(0, banners.length)
            JsonTool.jsonConvert.deserializeArray(response.data, Banner).forEach(v => banners.push(v))
        } catch (e) {
            console.log(e)
        } finally {
            // @ts-ignore
            component.loading = false
        }
    }

    static async findBanners(component: Vue, banners: Banner[], page: number, size: number, active: boolean | null, reference: BannerReference | null) {
        // @ts-ignore
        component.loading = true

        try {
            let response = await component.axios.get(ConstantTool.BASE_URL + "/public/banners", {
                params: { page: page, size: size, active: active, reference: reference }
            })
            banners.splice(0, banners.length)
            JsonTool.jsonConvert.deserializeArray(response.data, Banner).forEach(v => banners.push(v))
        } catch (e) {
            getModule(SnackbarModule).makeToast("No se han podido obtener los banners")
        } finally {
            // @ts-ignore
            component.loading = false
        }
    }

    static patchBanner(component: Vue, bannerId: number, banner: Banner, image: File | null) {
        (<any>component).loading = true
        let formData: FormData = new FormData()
        formData.set("name", banner.name!)
        formData.set("url", banner.url!)
        formData.set("type", banner.type!)
        formData.set("reference", banner.reference!)
        if (image) formData.set("image", image)

        component.axios.patch(ConstantTool.BASE_URL + "/api/banners/" + bannerId,
            formData, {
                headers: {Authorization: getModule(SessionModule).session.token}
            })
            .then(() => (<any>component).refresh())
            .catch(() => getModule(SnackbarModule).makeToast("No se pudo actualizar el banner"))
            .finally(() => (<any>component).loading = false)
    }

}
