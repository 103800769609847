


















import {Component, Prop, Vue} from "vue-property-decorator"
import Banner, {BannerReference} from "@/models/Banner"
import BannerService from "@/services/BannerService"
import {Any} from "json2typescript";

@Component
export default class CarouselComponent extends Vue {

	@Prop() readonly reference!: BannerReference
	@Prop({ default: '450px' }) readonly height!: string
	@Prop({ default: false }) readonly enableText!: boolean

	banners: Banner[] = []

	created() {
		BannerService.findBanners(this, this.banners, 0, 10, true, this.reference)
		// BannerService.getBannersByReference(this, this.banners, this.reference)
	}

	redirect(banner: Banner) {
      window.open(banner.url!, '_blank')
	}
}
